import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Philosophy = () => (
  <section className="titlesecondary-section">
    <div className="workenvironment-box-div">
      <div className="titlesecondary-description-box">
        <div className="workenvironment-box-title-grid">
          <div />
          <div
            className="workenvironment-cube"
            style={{ backgroundColor: `red` }}
          />
          <p className="workenvironment-title">Filosofía CENHIES</p>
          <div
            className="workenvironment-cube"
            style={{ backgroundColor: `red` }}
          />
          <div />
        </div>
        <p className="workenvironment-text">
          CENHIES está convencido que el individuo tiene la capacidad, la
          libertad y la responsabilidad de desarrollarse de forma autónoma en
          una sociedad global y cambiante; esto solo puede darse cuando 
          cuenta con las herramientas y el conocimiento que le permitan alcanzar
          su razón de ser y sentirse útil, seguro, realizado y dichoso en todos
          los ámbitos de su vida.
        </p>
      </div>
    </div>
    <StaticImage
      placeholder="blurred"
      src="../images/filosofía.png"
      alt=""
      style={{ borderColor: `red` }}
      className="titlemastery-philosophy-image"
    />
  </section>
)

export default Philosophy
