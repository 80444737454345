import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Mision = () => (
  <section className="teachingquality-section">
    <div className="teachingquality-content">
      <div className="teachingquality-grid">
        <div>
          <div className="teachingquality-box">
            <div className="teachingquality-icon-box">
              <div className="teachingquality-icon-before" />
              <StaticImage
                placeholder="blurred"
                src="../images/Mision.png"
                alt=""
                className={`teachingquality-icon icon-red`}
              />
            </div>
            <p className="teachingquality-title">Nuestra Misión</p>
            <p className="teachingquality-text">
              Somos una empresa que ofrece servicios educativos integrales de
              calidad, con programas académicos de excelencia desde el nivel
              básico hasta el superior, con personal altamente calificado y un
              alto sentido de responsabilidad social, basados en valores y
              principios.
            </p>
          </div>
        </div>
        <div>
          <div className="teachingquality-box">
            <div className="teachingquality-icon-box">
              <div className="teachingquality-icon-before" />
              <StaticImage
                placeholder="blurred"
                src="../images/Visio.png"
                alt=""
                className={`teachingquality-vision-icon icon-red`}
              />
            </div>
            <p className="teachingquality-title">Nuestra Visión</p>
            <p className="teachingquality-text">
              Ser una empresa educativa con calidad académica, reconocida por el
              mercado laboral al que se dirige como una de las escuelas privadas
              más importantes de la región, con un alto sentido de
              responsabilidad social basado en la innovación y valores,
              involucrando los avances tecnológicos, procesos de
              reestructuración organizacional, propiciando con ello la
              excelencia de sus estudiantes y consolidando el modelo educativo
              constructivista y sociocultural que facilite su proyección social
              y humana.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Mision
