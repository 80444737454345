import * as React from "react"
import Mision from "../components/Mision"
import Philosophy from "../components/Philosophy"

import Seo from "../components/Seo"

const AboutPage = () => (
  <>
    <Seo title="Conócenos" />
    <Philosophy />
    <Mision />
  </>
)

export default AboutPage
